<template>
  <div class="list-overview-aplied-casting">
    <b-row class="pb-1 mb-1 header-card-pitch">
      <b-col class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <b-avatar size="45" :src="getAssetUrl(aplied_casting_call.pitch.user.avatar)"></b-avatar>
          <span class="name-start">{{ `${aplied_casting_call.pitch.user.first_name} ${aplied_casting_call.pitch.user.last_name}` }}</span>
        </div>
        <b-button @click="$router.push({name: 'user_profile', params: {user: getUserData().meta_info.vanity_name}})" variant="light">
          {{ $t("creator.publicProfileCasting") }}
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-row class="m-0">
        <div class="text-left mb-1 w-100">
          <h5>{{ $t("creator.pitchCardCasting") }}</h5>
          <span v-if="aplied_casting_call.pitch.pitch_description">{{ aplied_casting_call.pitch.pitch_description }}</span>
          <div v-if="aplied_casting_call.pitch.type_pitch === 'video'">
            <video
              :src="isUrl(aplied_casting_call.pitch.video_url) ? aplied_casting_call.pitch.video_url : getAssetUrl(aplied_casting_call.pitch.video_url)"
              controls 
              class="video-preview-iframe-casting-aplied"
            ></video>
          </div>
        </div>
        <div class="mb-1 w-100 text-left">
          <h5>{{ $t("creator.askingPriceCasting") }}</h5>
          <p class="m-0">$ {{ numberFormat(aplied_casting_call.pitch.desired_payment, true) }}</p>
        </div>

        <div class="mb-1 w-100 text-left">
          <h5>{{ $t("creator.conectionsCasting") }}</h5>
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scroll-area-networks">
            <div 
              v-for="(network, index) in aplied_casting_call.pitch.user_networks" 
              :key="index" 
              class="d-flex align-items-center avatar-networks-pitches"
              @click="getLinkNetwork(network)"
            >
              <b-avatar variant="ligth" size="30px">
                <b-img :src="getIconWhite(network.network)" :class="getIconColor(network.network)" fluid></b-img>
              </b-avatar>
              <p class="m-0 ml-05">@{{network.username}}</p>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </b-row>
      <b-col class="m-0" v-if="aplied_casting_call.pitch.answers.length !== 0">
        <h5 class="w-100 text-left">{{ $t("creator.surveyAnswersCasting") }}</h5>
        <div class="d-flex flex-column"
          v-for="(answers, index) in aplied_casting_call.pitch.answers"
          :key="answers.uuid"
        >
        <span class="d-flex alignament-question mt-1"> {{ index + 1 }}. {{ answers.question.question }} </span>
          <span class="alignament-question pl-1 text-muted-gray"
            v-if="answers.question.type_question === 'radio' || answers.question.type_question === 'free_text'">
            {{ answers.answer }}
          </span>
          <span class="alignament-question pl-1 text-muted-gray" v-if="answers.question.type_question === 'checkbox'">
            {{ answers.answer.join(", ") }}
          </span>
          <div class="alignament-question pl-1 text-muted-gray" v-if="answers.question.type_question === 'media'">
            <p><a :href="getAssetUrl(answers.media)" target="_blank">{{ $t("creator.viewArchive") }}</a></p>
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
} from 'bootstrap-vue';
import utils from '@/libs/utils'; 
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { getIconWhite, getIconColor } from '@/libs/utils/icons';
import { getAssetUrl, isUrl, getURL } from '@/libs/utils/urls';
import { getUserData } from '@/libs/utils/user'
import { numberFormat } from '@/libs/utils/formats';

export default {
  name: "cardCastingSubmitted",
  components: {
    VuePerfectScrollbar,
    BRow,   
    BCol,
    BButton,
    BAvatar,
    BImg,
  },
  props: {
    aplied_casting_call: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      numberFormat,
      getURL,
      isUrl,
      getUserData,
      getAssetUrl,
      getIconWhite,
      getIconColor,
      utils,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        suppressScrollX: true,
      },
    }
  },
  methods: {
    getLinkNetwork(profile) {
      let url = ''
      if (profile.network === 'facebook' && profile.link) url = profile.link
      url = getURL(profile.network, profile.username, profile.is_channel)
      window.open(url, '_blank')
    },
  }
};
</script>
<style scoped>
.name-start {
  text-align: start !important;
  margin-inline: 10px;
}
.scroll-area-networks {
  position: relative;
  margin: auto;
  width: 100%;
  min-height: 50px;
  max-height: 200px;
}
.avatar-networks-pitches {
  padding: 0.3em;
}
.avatar-networks-pitches:hover {
  background-color: rgb(242, 241, 241);
  cursor: pointer;
  transition: all 300ms;
}
.ml-05 {
  display: block !important;
  margin-left: 0.3em !important;
}
.text-muted-gray {
  color: rgb(178, 178, 178);
}
</style>
<style>
.container-net {
  height: 300px;
}
.list-overview-aplied-casting {
  padding: 1em;
  border: 1px solid #eee5e5;
  margin-bottom: 2em;
  border-radius: 1em;
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 7em;
}
.header-card-pitch {
  border-bottom: 1px solid rgb(206, 212, 218) !important;
}
.button-profile-casting {
  display: flex;
  border: 1px solid #ced4da !important;
  background-color: #fff;
  align-items: center;
}
.line-network-pitch{
  border-bottom: 1px solid rgb(206, 212, 218) !important;
  padding: 3px;
}
.tex-networks-pitch {
  display: flex;
  font-size: 12px;
  align-items: start;  
}
.icon-network-casting {
  font-size: 15px;
  color: white !important;
}
.ellipsis-network {
  color: black;
  display: flex;
  align-items: start; 
  width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.alignament-question {
  display: flex;
  text-align: justify
}
.video-preview-iframe-casting-aplied {
  margin-top: 1em;
  width: 100% !important;
  border-radius: 1em;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
</style>